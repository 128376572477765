$feature-flags: (
  ui-shell: true,
  grid-columns-16: false,
);

@use '@carbon/react';
@use '@carbon/styles';
@use '@carbon/grid';
@use '@carbon/themes';
@import '@carbon/layout';
@import '@carbon/colors';
@import '@carbon/feature-flags';
@import '@carbon/motion';
@import '@carbon/type';
@include grid.flex-grid();
@import url('./Components/NoDataTable/nodata.scss');
